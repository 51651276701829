.homePage {
  font-family: "Arial Black";

  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    height: calc(10vh - 2em);
    background-color: #d6d6d6;
    position: sticky;

    img {
      height: 2em;
    }

    a {
      color: black;
      text-decoration: none;
      vertical-align: middle;
    }
  }

  .download {
    width: 100%;
    height: 90vh;
    background-image: url("assets/background-seats.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    div {
      border-radius: 20px;
      padding: 2em;
      margin-left: 10%;
      width: fit-content;
      background-color: #2f4c99;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      img {
        width: 500px;
      }

      button {
        margin-top: 2em;
        width: 100%;
        border-width: 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 60px;
        color: #2f4c99;
        font-family: "Gill Sans MT";
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        span {
          margin-left: 0.5em;
          display: inline;
        }
      }
    }
  }

  #documents {
    min-height: 100vh;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(
      to bottom,
      #d6d6d6,
      #d6d6d6 30%,
      #2f4c99 30%,
      #2f4c99
    );

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .document {
      background-color: #ededed;
      margin: 1em;
      height: 15em;
      box-sizing: border-box;
      padding: 2em;
      width: max-content;
      flex-grow: 1;

      .row {
        justify-content: flex-start;

        h2 {
          margin-left: 1em;
        }
      }

      .date {
        text-align: left;
        color: #6379b2;
        font-family: "Gill Sans MT";
        font-size: small;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      button {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        border-width: 0;
        background-color: white;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1em;
        color: #2f4c99;
        font-family: "Gill Sans MT";
        font-size: large;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        cursor: pointer;
      }

      button:disabled {
        background-color: #a5a5a5;
        color: white;
        cursor: default;
      }
    }
  }
}

body:has(.homePage) {
  margin: 0;
}

* {
  transition: 0.3s ease-in-out;
  scroll-behavior: smooth;
}
