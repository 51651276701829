.err404 {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  align-items: center;
  text-align: center;

  #logo {
    height: min(80dvh, calc(100dvh - 10em));
    max-width: 100%;
    display: inline-block;
  }

  #logo + h1 {
    font-size: medium;
  }
}
